<template>
    <div>
        <div class="modal fade" id="expeditionModal" tabindex="-1" aria-labelledby="expeditionModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="expeditionModalLabel" class="font-weight-bold">{{ModalTitle}}{{ExpeditionCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CRow>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold required">Kode</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="ExpeditionCode" v-model="ExpeditionCode" class="font-weight-bold" />
                                <label id="errorExpeditionCode" class="form-error" style="display: none; color: red;"></label>
                            </CCol>               
                        </CRow>
                        <CRow>
                             <CCol sm="2">
                                <label class="form-label font-weight-bold required">Nama</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput id="ExpeditionName" v-model="ExpeditionName" class="font-weight-bold" />
                                <label id="errorExpeditionName" class="form-error" style="display: none; color: red;"></label>
                            </CCol>    
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>                                     
                        </CRow>
                        <hr>

                        <CTabs>
                            <CTab title="Profil" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Provinsi</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="Province" class="pb-3" :options="ProvinceData" v-model="Province" @input="onChange()" />
                                            <label id="errorProvince" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Bebas Pajak</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="2">
                                            <div>
                                                <input type="checkbox" v-model="TaxFree"/> <label>Iya</label>
                                            </div>
                                            <label id="errorTaxFree" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Kota</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <v-select id="City" class="pb-3" :options="CityData" v-model="City"/>
                                            <label id="errorCity" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>     
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">Alamat</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Address"></textarea>
                                            <label id="errorAddress" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone1" v-model="Phone1" class="font-weight-bold" />
                                            <label id="errorPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Telp 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Phone2" v-model="Phone2" class="font-weight-bold" />
                                            <label id="errorPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Handphone 1</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone1" v-model="HandPhone1" class="font-weight-bold" />
                                            <label id="errorHandPhone1" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                       <CCol sm="2">
                                            <label class="form-label font-weight-bold">Handphone 2</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="HandPhone2" v-model="HandPhone2" class="font-weight-bold" />
                                            <label id="errorHandPhone2" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NPWP</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NPWP" v-model="NPWP" class="font-weight-bold" />
                                            <label id="errorNPWP" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Grade</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="Grade" v-model="Grade" class="font-weight-bold" />
                                            <label id="errorGrade" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold required">NIK</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="NIK" v-model="NIK" class="font-weight-bold" />
                                            <label id="errorNIK" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="2">
                                            <label class="form-label font-weight-bold">Catatan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol> 
                                        <CCol sm="4" class="pb-3">
                                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                            <label id="errorNote" class="form-error pt-3" style="display: none; color: red;"></label>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CTab>
                        </CTabs>
                        <br>
                        <label id="errorExpeditionGeneral" class="form-error" style="display: none; color: red;"></label>
                        <br>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import expeditionServices from '../Script/ExpeditionServices.js';
import ProvinceCityJson from '../../../../assets/jsons/province.json';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'ExpeditionForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',
            SaveType : '',
            Error: 0,
            ProfileError : 0,
            ProvinceCity: ProvinceCityJson,

            ExpeditionId : '',
            ExpeditionCode : '',
            GridId : '',
            ExpeditionName : '',
            Status : '',
            StatusData: [],

            //Profile
            Province : '',
            ProvinceData : [],
            TaxFree : '',
            City : '',
            CityData : [],
            Address : '',
            Phone1 : '',
            Phone2 : '',
            HandPhone1 : '',
            HandPhone2 : '',
            NPWP : '',
            Grade : '',
            NIK : '',
            Note : '',
            
            gridDetailReload: 0,
        }
    },
    methods: {
        addClick(){   
            $(".form-error").css("display", "none");  
            this.ModalTitle = 'Add Expedition: ';

            this.ExpeditionCode = 'Auto';
            this.ExpeditionName = '';
            this.StatusData = [{value: 'N', label: 'New'}];
            this.Status = this.StatusData[0].value;

            this.TaxFree = false;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == "DKI Jakarta");
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == "Jakarta Utara");

            this.Address = '';
            this.Phone1 = '';
            this.Phone2 = '';
            this.HandPhone1 = '';
            this.HandPhone2 = '';
            this.NPWP = '';
            this.Grade = '';
            this.NIK = '';
            this.Note = '';

            this.GridId = '';
            this.SaveType = 'Add';
            $('#tab-1').trigger('click');
            document.getElementById('ExpeditionCode').readOnly = false;
            window.$('#expeditionModal').modal('show');
        },
        async editClick(id, View) {
            var data = await expeditionServices.getDetailContact(id);

            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.ModalTitle = 'Edit Expedition: ';
            this.ExpeditionId = data.contact_id;
            this.ExpeditionCode = data.contact_code;
            this.ExpeditionName = data.contact_name;
            
            this.StatusData = [ {value: 'A', label: 'Active'},
                                {value: 'I', label: 'Inactive'}];
            this.Status = data.status=="N"?"A":data.status;
            
            var profileData = data.metadata.profile;
            this.TaxFree = profileData.taxfree;

            this.ProvinceData = this.$globalfunc.getProvinceData();
            this.Province = this.ProvinceData.find(c => c.value == profileData.province);
            this.CityData = this.$globalfunc.getCityData(this.Province.value);
            this.City = this.CityData.find(c => c.value == profileData.city);

            this.Address = profileData.address;
            this.Phone1 = profileData.phone1;
            this.Phone2 = profileData.phone2;
            this.HandPhone1 = profileData.handphone1;
            this.HandPhone2 = profileData.handphone2;
            this.NPWP = profileData.npwp;
            this.Grade = profileData.grade;
            this.NIK = profileData.nik;
            this.Note = profileData.note;
            
            this.GridId = this.ExpeditionId;
            this.SaveType = 'Edit';
            $('#tab-1').trigger('click');
            document.getElementById('ExpeditionCode').readOnly = true;
            this.reloadDetail();

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Expedition: ';
            }

            window.$('#expeditionModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            this.ProfileError = 0;

            $(".form-error").css("display", "none");

            if(this.ExpeditionCode == ''){
                this.errorShow('errorExpeditionCode');
            }
            if(this.ExpeditionName == ''){
                this.errorShow('errorExpeditionName');
            }
            if(this.Address == ''){
                this.errorShow('errorAddress');
                this.ProfileError++;
            }
            if(this.NPWP == ''){
                this.errorShow('errorNPWP');
                this.ProfileError++;
            }
            if(this.NIK == ''){
                this.errorShow('errorNIK');
                this.ProfileError++;
            }
            if(this.ProfileError != 0){
                this.$swal("Error", "Terdapat error pada profile tab", "error");
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                const profile = {
                    province : this.Province.value,
                    taxfree : this.TaxFree,
                    city :  this.City.value,
                    address : this.Address,
                    phone1 : this.Phone1,
                    phone2 : this.Phone2,
                    handphone1 : this.HandPhone1,
                    handphone2 : this.HandPhone2,
                    npwp : this.NPWP,
                    grade : this.Grade,
                    nik : this.NIK,
                    note : this.Note
                }

                const metadata = {
                    profile : profile
                }

                const expedition = {
                    contact_code : this.ExpeditionCode,
                    contact_type : "Expedition",
                    contact_name : this.ExpeditionName,
                    status : this.Status,
                    metadata : metadata,
                    attachment1: null,
                    attachment2: null,
                }
                
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : expedition
                    }

                    expeditionServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#expeditionModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.ExpeditionId,
                        data : expedition
                    }

                    expeditionServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#expeditionModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        onChange(){
            this.City = '';
            if (this.Province == null){
                this.CityData = []
            }
            else{
                this.CityData = this.$globalfunc.getCityData(this.Province.value);
            }
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
    }
}
</script>
